import styled, { DefaultTheme } from 'styled-components';
import { space, variant, typography } from 'styled-system';
import { Colors } from '../../theme/types';
import { scaleVariants, styleVariants } from './theme';
import { TagProps, variants } from './types';

interface ThemedProps extends TagProps {
  theme: DefaultTheme;
}

const getOutlineStyles = ({ outline, theme, variant: variantKey = variants.PRIMARY }: ThemedProps) => {
  if (outline) {
    const themeColorKey = styleVariants[variantKey].backgroundColor as keyof Colors;
    const color = theme.colors[themeColorKey];

    return `
      color: ${color};
      background: transparent;
      border: 2px solid ${color};
    `;
  }

  return '';
};

export const StyledTag = styled.div<ThemedProps>`
  align-items: center;
  border-radius: 10px;
  color: #fde53a !important;
  height: 42px !important;
  display: inline-flex;
  font-size: 14px !imporant;
  font-weight: 500 !important;
  white-space: nowrap;
  padding: 12px 16px !important;
  border: 1px solid #f4762133 !important;
  background: linear-gradient(180deg, rgba(244, 118, 33, 0.1) -66.22%, rgba(244, 118, 33, 0.05) 85.46%) !important;

  & > svg {
    fill: currentColor;
  }

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}

  ${variant({
    prop: 'scale',
    variants: scaleVariants,
  })}
  ${variant({
    variants: styleVariants,
  })}
  ${space}
  ${typography}

  ${getOutlineStyles}
`;

export default null;
